@import "../../Variables";

a {
  color: inherit;
  text-decoration-style: dotted;
}

.paper-sheet {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 21cm;
  min-width: 21cm;
  // max-height: 29.7cm;
  // height: 29.7cm;
  margin: 50px;
  border: 1px solid darkgray;
  padding: 1.5rem;

  .content {
    display: flex;
    flex-grow: 1;
    margin-top: 1rem;

    .left-column {
      display: flex;
      flex-direction: column;
      width: 25%;
      border-right: 1px solid gray;
      padding-right: 1.5rem;

      .section-body {
        padding: 1rem;
      }
    }
    .right-column {
      display: flex;
      flex-direction: column;
      padding-left: 1rem;
      flex-grow: 1;

      .section-body {
        padding: 0.7rem 1rem 0 0;
      }
    }
  }
}

@media print {
  @page {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  html {
    font-size: 12px;
  }
  .paper-sheet {
    margin: 0px;
    border: 0px;
    padding: 1.3rem;
    overflow: hidden;
    width: 21cm;
    min-width: 21cm;
    max-height: 28.7cm;
    height: 28.7cm;
    .content {
      padding: 1rem;
    }
  }
}

@media only screen and (max-width: $medium-breakpoint) {
  .content {
    flex-direction: column;

    .left-column {
      width: 100% !important;
    }

    .right-column {
      padding-left: 0 !important;
    }
  }
  .paper-sheet {
    width: 100%;
    min-width: 100%;
    height: 100%;
    margin: 0;
    border: 0;
  }
}
