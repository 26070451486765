@import "../../Variables";

.section {
  display: flex;
  flex-direction: column;

  .section-header {
    display: flex;
    font-size: 1.5rem;
    border-bottom: 1px solid gray;
    padding-top: 0.7rem;

    .section-icon {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
    }
    .section-title {
      padding-left: 0.5rem;
      text-transform: uppercase;
    }
  }

  @import "Cv";
  @import "SmallSectionContent";
  @import "Experience";
  @import "Skill";
  @import "Education";
}

@media only screen and (max-width: $medium-breakpoint) {
  // .paper-sheet {
  //   border: 0px;
  // }
  .section {
    margin: 0 1.5rem;
  }
}
