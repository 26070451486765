@import "Variables";

.App {
  display: flex;
  justify-content: center;
  background-color: aliceblue;
}

@media only screen and (max-width: $medium-breakpoint) {
  // .App {
  //   background-color: white;
  // }
}
