.large-section-item {
  display: flex;
  padding-bottom: 0.3rem;

  .large-section-item-icon {
    margin-right: 1.2rem;
    font-style: italic;
    color: grey;
  }
  .large-section-item-content {
    display: flex;
    flex-direction: column;
    padding-top: 2px;
    width: 100%;

    .large-section-item-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .large-section-item-company {
      font-size: 1rem;
      padding-left: 2px;
      font-weight: 500;
    }
    .large-section-item-period {
      font-size: 0.8rem;
    }

    .large-section-item-value {
      text-align: justify;
      font-size: 0.9rem;
      padding: 2px 0;
    }

    .large-section-item-details {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      color: #655a5a;
      padding: 0.1rem;

      .large-section-item-details-group {
        display: flex;

        .large-section-item-details-company {
          font-size: 0.85rem;
        }
      }
    }

    .large-section-item-tags {
      font-size: 0.9rem;
      font-weight: 400;
      color: #54557f;
    }
  }
}

@media only screen and (max-width: $small-breakpoint) {
  .large-section-item-icon {
    display: none;
  }
  .large-section-item-content {
    margin-bottom: 15px;
    .large-section-item-wrapper {
      flex-direction: column;
      .large-section-item-company {
        display: flex;
        justify-content: space-between;
        font-size: 1.16rem;
        align-items: center;
        & span {
          font-style: italic;
          font-size: small;
        }
      }
      .large-section-item-period {
        padding: 0.1rem 0 0.1rem 0.3rem;
        font-size: 0.9rem;
      }
    }
  }
  .large-section-item-value,
  .large-section-item-tags {
    padding: 5px !important;
  }
  .large-section-item-details-group {
    flex-direction: column;
  }
}
